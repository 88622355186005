import { Typography } from '@components/ui/Typography'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import { useCallback } from 'react'
import * as Styled from './styles'

export const BackToTopView = (): JSX.Element => {
    const handleScrollTop = useCallback(() => {
        window.scroll({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <Styled.Container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={16}
            onClick={handleScrollTop}
            fullWidth
        >
            <Typography size="base" fontFamily="medium">
                Voltar ao início
            </Typography>
            <ArrowUpward />
        </Styled.Container>
    )
}
