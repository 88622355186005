import { WhatsappIcon } from '@assets/svg/WhatsappIcon'
import { CustomLink } from '@components/CustomLink'
import { Stack } from '@components/Stack'
import { ColoredSvg } from '@components/ui/ColoredSvg'
import { Typography } from '@components/ui/Typography'
import { EXTERNAL_LINKS } from '@constants/common'
import { Routes } from '@service/routes'
import { themeStyled } from '@theme/theme'
import { SocialMedia } from '../components/SocialMedia'
import { FOOTER_PHONES } from '../constants'
import * as Styled from './styles'

export const ContactsAndInstitutionalDesktopView = (): JSX.Element => {
    return (
        <Styled.ContainerStack>
            <Styled.ContactsContainer>
                <Typography size="xl" fontFamily="medium" variant="paragraph">
                    Televendas
                </Typography>
                <Styled.SacButton
                    color="primary"
                    size="small"
                    fullWidth
                    href={EXTERNAL_LINKS.televendas_whatsapp_web}
                    icon={
                        <ColoredSvg
                            color={themeStyled.colors.gray[800]}
                            svg={<WhatsappIcon />}
                        />
                    }
                >
                    {FOOTER_PHONES.televendas.text}
                </Styled.SacButton>

                <Styled.ContactsSubContainerBottom>
                    <Stack fullWidth spacing={12}>
                        <Typography size="xl" fontFamily="medium">
                            Atendimento ao cliente
                        </Typography>
                        <Stack direction="row" spacing={10} alignItems="center">
                            <ColoredSvg
                                color={themeStyled.colors.primary[500]}
                                svg={<WhatsappIcon />}
                            />
                            <Styled.ContactContainer
                                size="sm"
                                fontFamily="regular"
                            >
                                <CustomLink
                                    href={EXTERNAL_LINKS.contato_whatsapp_web}
                                    target="_blank"
                                >
                                    {FOOTER_PHONES.whatsapp.text}
                                </CustomLink>
                            </Styled.ContactContainer>
                        </Stack>
                    </Stack>
                </Styled.ContactsSubContainerBottom>

                <CustomLink href={Routes.callCenter}>
                    <Styled.CallCenterStack>
                        <Styled.HeadsetMicIcon />
                        <Stack>
                            <Typography
                                size="base"
                                fontFamily="medium"
                                variant="paragraph"
                            >
                                Acesse a
                            </Typography>
                            <Typography size="base" fontFamily="medium">
                                Central de Atendimento
                            </Typography>
                        </Stack>
                    </Styled.CallCenterStack>
                </CustomLink>

                <SocialMedia />
            </Styled.ContactsContainer>
        </Styled.ContainerStack>
    )
}
