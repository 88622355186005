import { Stack } from '@components/Stack'
import styled from 'styled-components'

export const Container = styled(Stack)`
    background: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.gray[600]};
    color: ${({ theme }) => theme.colors.white};
    min-height: 49px;
    cursor: pointer;
`
