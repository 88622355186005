import { CustomImage } from '@components/CustomImage'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

const Text = styled(Typography).attrs({
    fontFamily: 'medium',
    size: 'xl',
    variant: 'span'
})`
    color: ${({ theme }) => theme.colors.white};
    white-space: nowrap;
    margin-bottom: 16px;
`
const QrImage = styled(CustomImage)`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 2px;
`

export { Text, QrImage }
