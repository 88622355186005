import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

export const Text = styled(Typography).attrs({
    fontFamily: 'regular',
    size: 'base'
})`
    color: ${({ theme }) => theme.colors.white};
`

export const CategoryItems = styled(Stack).attrs({
    direction: 'row',
    spacing: 10
})`
    a {
        color: ${({ theme }) => theme.colors.white};
    }
`

export const CategoryContainer = styled(Stack).attrs({
    spacing: 12
})`
    width: 206px;
`

export const CategoryTitle = styled(Typography).attrs({
    size: 'xl'
})`
    color: ${({ theme }) => theme.colors.white};
`
