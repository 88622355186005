import { useLoading } from '@contexts/loading'
import { getLastPathPart } from '@utils/url'

export const footerCategoriesDesktopIO = () => {
    const { showLoading } = useLoading()

    const handleOnClick = (link: string) => {
        if (getLastPathPart(window.location.pathname) !== getLastPathPart(link))
            showLoading()
    }

    return {
        handleOnClick
    }
}

export type FooterCategoriesDesktopIO = ReturnType<
    typeof footerCategoriesDesktopIO
>
