import * as Styled from './styles'

export const ConstitutionalAreaView = (): JSX.Element => {
    return (
        <Styled.Container>
            <Styled.SubContainer>
                <Styled.Text>
                    Devido à Emenda Constitucional nº 87/2015, o eFácil não
                    atenderá temporariamente pedidos para entrega de produtos
                    nos estados do AC, AP e RR. Não realizamos entregas em área
                    Rural.
                </Styled.Text>
                <Styled.Text>
                    Frete grátis válido para compras acima de R$100,00,
                    exclusivo para produtos vendidos e entregues pelo eFácil, no
                    Aplicativo válido para Minas Gerais e no Site para o
                    Triângulo Mineiro.
                </Styled.Text>
                <Styled.Text>
                    As condições comerciais (Disponibilidade de Estoque, Preço,
                    Valor do Frete e Prazo de entrega) são válidas para a região
                    de entrega informada. Para maiores informações, consulte
                    nossos Termos de Uso. Acesse o site do Martins Atacado se
                    deseja comprar para Revenda.
                </Styled.Text>
                <Styled.Text>
                    Martins Comércio e Serviço de Distribuição S.A. /
                    www.efacil.com.br / Rua Jataí, 1.150 - Bairro Aparecida /
                    Uberlândia - MG CEP:38400-632. CNPJ 43.214.055/0001-07 /
                    Inscrição Estadual: 702.513.460-0075
                </Styled.Text>
            </Styled.SubContainer>
        </Styled.Container>
    )
}
