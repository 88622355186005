import * as Styled from './styles'
import { Routes } from '@service/routes'
import { CustomLink } from '@components/CustomLink'
import { Typography } from '@components/ui/Typography'

export const InstitutionalView = (): JSX.Element => {
    return (
        <Styled.ContainerStack>
            <Typography size="xl" fontFamily="medium">
                Institucional
            </Typography>
            <Styled.SubContainerStack>
                <Styled.ItemContainer>
                    <CustomLink href={Routes.about} target="_parent">
                        <Styled.Text>Sobre o eFácil</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.terms} target="_parent">
                        <Styled.Text>Termos de uso</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.privacy} target="_parent">
                        <Styled.Text>Política de privacidade</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.devolutions} target="_parent">
                        <Styled.Text>Trocas e devoluções</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.regulation} target="_parent">
                        <Styled.Text>Regulamento das Campanhas</Styled.Text>
                    </CustomLink>
                </Styled.ItemContainer>

                <Styled.ItemContainer>
                    <CustomLink href={Routes.lpPoints} target="_parent">
                        <Styled.Text>Pontos eFácil</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.warranty} target="_parent">
                        <Styled.Text>Garantia Estendida</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.safety} target="_parent">
                        <Styled.Text>Guia de Segurança</Styled.Text>
                    </CustomLink>
                    <CustomLink href={Routes.evaluate} target="_parent">
                        <Styled.Text>Avalie o eFácil</Styled.Text>
                    </CustomLink>
                </Styled.ItemContainer>
            </Styled.SubContainerStack>
        </Styled.ContainerStack>
    )
}
