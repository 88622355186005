import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'
import styled from 'styled-components'

const Container = styled(Stack).attrs({
    justifyContent: 'center',
    fullWidth: true,
    alignItems: 'center'
})`
    padding: 8px 5px;
    background-color: ${({ theme }) => theme.colors.white};
`

const Text = styled(Typography).attrs({
    size: 'xs',
    fontFamily: 'regular',
    textAlign: 'center'
})`
    margin-top: 3px;
    color: ${({ theme }) => theme.colors.gray[900]};
`

const SubContainer = styled(Stack).attrs({
    justifyContent: 'center',
    alignItems: 'center',
    fullWidth: true
})``

export { Container, Text, SubContainer }
