import styled from 'styled-components'
import { Typography } from '@components/ui/Typography'

export const Container = styled.div`
    width: 100%;
    background: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.gray[900]};
`

export const CategoryTitle = styled(Typography).attrs(({ theme }) => ({
    size: 'xl',
    fontFamily: 'medium',
    variant: 'paragraph',
    color: theme.colors.white
}))`
    text-align: center;
    margin: 16px 0;
`
