import { FooterCategoriesProps } from '../components/FooterCategories/view'
import { BackToTop } from '../components/BackToTop'
import { ContactsAndInstitutional } from '../components/ContactsAndInstitutional'
import { RelatedAndLogo } from '../components/RelatedAndLogo'
import { FooterCategories } from '../components/FooterCategories'
import dynamic from 'next/dynamic'
import { ErrorBoundary } from 'react-error-boundary'

const Newsletter = dynamic(
    () => import('../components/Newsletter').then(module => module.Newsletter),
    { ssr: false }
)

export const FooterMobileView = ({
    categoriesData,
    showNewsletter
}: FooterCategoriesProps): JSX.Element => {
    return (
        <ErrorBoundary
            fallback={<></>}
            onError={error => console.error('Client Side Error:', error)}
        >
            {showNewsletter && <Newsletter />}
            <FooterCategories categoriesData={categoriesData} />
            <ContactsAndInstitutional />
            <RelatedAndLogo />
            <BackToTop />
        </ErrorBoundary>
    )
}
