import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { Typography } from '@components/ui/Typography'
import { EXTERNAL_LINKS } from '@constants/common'
import { themeStyled } from '@theme/theme'
import * as Styled from './styles'

const assets = {
    camara: '/img/camara.png',
    trustvox: '/img/trustvox.png',
    google: '/img/google-safe.png',
    martins: '/img/martins.png'
}

const ebitLogo = 'https://newimgebit-a.akamaihd.net/ebitBR/selo/img_857.png'

export const RelatedAndLogoView = (): JSX.Element => {
    return (
        <Styled.Container idName="footerLogos">
            <Styled.SubContainer>
                <Styled.Related>
                    <CustomLink href={EXTERNAL_LINKS.ebit} target="_blank">
                        <CustomImage
                            src={ebitLogo}
                            width={40}
                            height={56}
                            layout="fixed"
                            alt="ebit"
                        />
                    </CustomLink>
                    <CustomLink href={EXTERNAL_LINKS.google} target="_blank">
                        <CustomImage
                            src={assets.google}
                            width={96}
                            height={27}
                            layout="fixed"
                            alt="Google safe"
                        />
                    </CustomLink>
                    <CustomLink href={EXTERNAL_LINKS.camara} target="_blank">
                        <CustomImage
                            src={assets.camara}
                            width={128}
                            height={28}
                            layout="fixed"
                            alt="camara"
                        />
                    </CustomLink>
                    <CustomLink href={EXTERNAL_LINKS.trustvox} target="_blank">
                        <CustomImage
                            src={assets.trustvox}
                            width={40}
                            height={40}
                            layout="fixed"
                            alt="trustvox"
                        />
                    </CustomLink>
                </Styled.Related>
                <Styled.ContainerLogo idName="logoMartinsFooter">
                    <Typography
                        size="sm"
                        fontFamily="regular"
                        color={themeStyled.colors.gray[500]}
                    >
                        O eFácil é uma empresa do Grupo
                    </Typography>
                    <CustomLink href={EXTERNAL_LINKS.martins} target="_blank">
                        <CustomImage
                            src={assets.martins}
                            width={90}
                            height={90}
                            layout="fixed"
                            alt="martins"
                        />
                    </CustomLink>
                </Styled.ContainerLogo>
            </Styled.SubContainer>
        </Styled.Container>
    )
}
