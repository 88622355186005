import { useDeviceContext } from '@contexts/device'
import { FooterCategoriesProps } from './components/FooterCategories/view'
import { FooterDesktop } from './desktop'
import { FooterMobile } from './mobile'

export const FooterView = ({
    categoriesData,
    showNewsletter = true
}: FooterCategoriesProps): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <FooterDesktop
            categoriesData={categoriesData}
            showNewsletter={showNewsletter}
        />
    ) : (
        <FooterMobile
            categoriesData={categoriesData}
            showNewsletter={showNewsletter}
        />
    )
}
