import { FooterCategoriesProps } from '../components/FooterCategories/view'
import { ContactsAndInstitutional } from '../components/ContactsAndInstitutional'
import { RelatedAndLogo } from '../components/RelatedAndLogo'
import { ConstitutionalArea } from '../components/ConstitutionalArea'
import { Institutional } from '../components/ContactsAndInstitutional/components/Institutional'
import { FooterCategories } from '../components/FooterCategories'
import { QrCode } from '../components/QrCode'
import * as Styled from './styles'
import dynamic from 'next/dynamic'
import { ErrorBoundary } from 'react-error-boundary'

const Newsletter = dynamic(
    () => import('../components/Newsletter').then(module => module.Newsletter),
    { ssr: false }
)

export const FooterDesktopView = ({
    categoriesData,
    showNewsletter = true
}: FooterCategoriesProps): JSX.Element => {
    return (
        <ErrorBoundary
            fallback={<></>}
            onError={error => console.error('Client Side Error:', error)}
        >
            {showNewsletter && <Newsletter />}
            <Styled.Container>
                <Styled.SubContainer>
                    <ContactsAndInstitutional />
                    <Institutional />
                    <FooterCategories categoriesData={categoriesData} />
                    <QrCode />
                </Styled.SubContainer>
            </Styled.Container>

            <RelatedAndLogo />
            <ConstitutionalArea />
        </ErrorBoundary>
    )
}
