import { Stack } from '@components/Stack'
import { Button } from '@components/ui/Button'
import { Typography } from '@components/ui/Typography'
import { HeadsetMic } from '@material-ui/icons'
import styled from 'styled-components'

export const ContainerStack = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'space-between'
})`
    color: ${({ theme }) => theme.colors.gray[50]};
`

export const SacButton = styled(Button)`
    margin-top: 24px;

    .MuiButton-label {
        color: ${({ theme }) => theme.colors.gray[800]};
    }
`

export const CallCenterStack = styled(Stack).attrs({
    direction: 'row',
    spacing: 17,
    alignItems: 'center'
})`
    margin: 24px 0;
    cursor: pointer;
`

export const HeadsetMicIcon = styled(HeadsetMic)`
    width: 40px;
    height: 40px;
`
export const Divider = styled.span`
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
    opacity: 20%;
    margin: 24px 0;
`
export const ContactsContainer = styled(Stack).attrs({
    justifyContent: 'flex-start'
})``

export const ContactsSubContainer = styled(Stack).attrs({
    direction: 'row',
    spacing: 34,
    alignItems: 'flex-start'
})`
    margin-top: 20px;
`

export const ContactsSubContainerBottom = styled(Stack).attrs({
    direction: 'row',
    spacing: 34,
    alignItems: 'flex-start'
})`
    margin-top: 20px;
`

export const ContactContainer = styled(Typography)`
    white-space: nowrap;
`
