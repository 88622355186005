import { Stack } from '@components/Stack'
import styled from 'styled-components'
import { DESKTOP_SIZE } from '@constants/device'

export const Container = styled(Stack).attrs({
    spacing: 12,
    fullWidth: true,
    alignItems: 'center',
    justifyContent: 'center'
})`
    background-color: ${({ theme }) => theme.colors.gray[50]};
`

export const SubContainer = styled(Stack).attrs(({ theme }) => ({
    fullWidth: true,
    alignItems: 'center',
    padding: theme.isDesktop ? '16px 0px' : '16px 16px',
    direction: theme.isDesktop ? 'row-reverse' : 'column',
    justifyContent: theme.isDesktop && 'space-between'
}))`
    max-width: ${({ theme }) => theme.isDesktop && DESKTOP_SIZE}px;
    margin: ${({ theme }) => theme.isDesktop && '0 auto'};
`

export const ContainerLogo = styled(Stack).attrs(({ theme }) => ({
    spacing: 0,
    alignItems: 'center',
    fullWidth: !theme.isDesktop
}))``

export const Related = styled(Stack).attrs(({ theme }) => ({
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    spacing: theme.isDesktop ? 24 : 8
}))``
