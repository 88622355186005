import { useDeviceContext } from '@contexts/device'
import { ContactsAndInstitutionalDesktop } from './desktop'
import { ContactsAndInstitutionalMobile } from './mobile'

export const ContactsAndInstitutionalView = (): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <ContactsAndInstitutionalDesktop />
    ) : (
        <ContactsAndInstitutionalMobile />
    )
}
