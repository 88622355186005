import { useDeviceContext } from '@contexts/device'
import { CategoriesData } from '@store/modules/department/types'
import { FooterCategoriesDesktop } from './desktop'
import { FooterCategoriesMobile } from './mobile'

export type FooterCategoriesProps = {
    categoriesData?: CategoriesData | null
    showNewsletter?: boolean
}

export const FooterCategoriesView = ({
    categoriesData
}: FooterCategoriesProps): JSX.Element => {
    const { isDesktop } = useDeviceContext()

    return isDesktop ? (
        <FooterCategoriesDesktop categories={categoriesData?.start} />
    ) : (
        <FooterCategoriesMobile categoriesData={categoriesData} />
    )
}
