import { Stack } from '@components/Stack'
import * as Styled from './styles'

const assets = {
    qr: '/img/qrcode_app.png'
}

export const QrCodeView = () => {
    return (
        <Stack>
            <Styled.Text>Baixe o APP eFácil</Styled.Text>
            <Styled.QrImage
                src={assets.qr}
                width={128}
                height={128}
                layout="fixed"
            />
        </Stack>
    )
}
