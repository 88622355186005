import { CustomLink } from '@components/CustomLink'
import { Routes } from '@service/routes'
import { Category } from '@store/modules/department/types'
import { getURLSafe } from '@utils/url'
import { IOProps } from 'react-compose-io'
import { FooterCategoriesDesktopIO } from './io'
import * as Styled from './styles'

type FooterCategoriesProps = {
    categories?: Category[]
}

export const FooterCategoriesDesktopView = ({
    _io,
    categories
}: IOProps<FooterCategoriesDesktopIO, FooterCategoriesProps>): JSX.Element => {
    return (
        <Styled.CategoryContainer>
            <Styled.CategoryTitle fontFamily="medium" size="xl">
                Categorias
            </Styled.CategoryTitle>
            {categories?.map(category => (
                <Styled.CategoryItems key={category.idCategoria}>
                    <CustomLink
                        href={`${Routes.department}/[...slug].tsx`}
                        as={getURLSafe(category.link).pathname}
                        data-testid="linked-menu-item"
                        onClick={() => _io.handleOnClick(category.link)}
                    >
                        <Styled.Text>{category.nome}</Styled.Text>
                    </CustomLink>
                </Styled.CategoryItems>
            ))}
        </Styled.CategoryContainer>
    )
}
