import { FacebookIcon } from '@assets/svg/FacebookIcon'
import { InstagramIcon } from '@assets/svg/InstagramIcon'
import { YoutubeIcon } from '@assets/svg/YoutubeIcon'
import { CustomImage } from '@components/CustomImage'
import { CustomLink } from '@components/CustomLink'
import { Stack } from '@components/Stack'
import { EXTERNAL_LINKS } from '@constants/common'

export const SocialMediaView = (): JSX.Element => {
    const assets = {
        simplifica: '/img/simplifica-icon.png'
    }

    return (
        <Stack direction="row" spacing={32}>
            <CustomLink href={EXTERNAL_LINKS.facebook} target="_blank">
                <FacebookIcon />
            </CustomLink>
            <CustomLink href={EXTERNAL_LINKS.instagram} target="_blank">
                <InstagramIcon />
            </CustomLink>
            <CustomLink href={EXTERNAL_LINKS.youtube} target="_blank">
                <YoutubeIcon />
            </CustomLink>
            <CustomLink href={EXTERNAL_LINKS.simplifica} target="_blank">
                <CustomImage src={assets.simplifica} width={75} height={32} />
            </CustomLink>
        </Stack>
    )
}
