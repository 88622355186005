import styled from 'styled-components'
import { Stack } from '@components/Stack'
import { DESKTOP_SIZE } from '@constants/device'

export const Container = styled(Stack).attrs({
    fullWidth: true,
    justifyContent: 'center',
    alignItems: 'center'
})`
    background: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.gray[800]};
    padding: 32px 34px;
`

export const SubContainer = styled(Stack).attrs({
    direction: 'row',
    justifyContent: 'space-between',
    fullWidth: true
})`
    max-width: ${DESKTOP_SIZE}px;
`
