import { WhatsappIcon } from '@assets/svg/WhatsappIcon'
import { CustomLink } from '@components/CustomLink'
import { Stack } from '@components/Stack'
import { ColoredSvg } from '@components/ui/ColoredSvg'
import { Typography } from '@components/ui/Typography'
import { EXTERNAL_LINKS } from '@constants/common'
import { Routes } from '@service/routes'
import { themeStyled } from '@theme/theme'
import { Institutional } from '../components/Institutional'
import { SocialMedia } from '../components/SocialMedia'
import { FOOTER_PHONES } from '../constants'
import * as Styled from './styles'

export const ContactsAndInstitutionalMobileView = (): JSX.Element => {
    return (
        <Styled.ContainerStack>
            <Typography size="xl" fontFamily="medium" variant="paragraph">
                Televendas
            </Typography>
            <Styled.SacButton
                color="primary"
                size="small"
                fullWidth
                href={EXTERNAL_LINKS.televendas_whatsapp_app}
                icon={
                    <ColoredSvg
                        color={themeStyled.colors.gray[800]}
                        svg={<WhatsappIcon />}
                    />
                }
            >
                {FOOTER_PHONES.televendas.text}
            </Styled.SacButton>
            <Stack direction="row" justifyContent="space-evenly">
                <Stack fullWidth spacing={12}>
                    <Typography size="xl" fontFamily="medium">
                        Atendimento ao cliente
                    </Typography>
                    <Stack direction="row" spacing={10} alignItems="center">
                        <ColoredSvg
                            color={themeStyled.colors.primary[500]}
                            svg={<WhatsappIcon />}
                        />
                        <Typography size="sm" fontFamily="regular">
                            <CustomLink
                                href={EXTERNAL_LINKS.contato_whatsapp_app}
                                target="_blank"
                            >
                                {FOOTER_PHONES.whatsapp.text}
                            </CustomLink>
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>

            <CustomLink href={Routes.callCenter}>
                <Styled.CallCenterStack
                    direction="row"
                    spacing={17}
                    alignItems="center"
                >
                    <Styled.HeadsetMicIcon />
                    <Stack>
                        <Typography
                            size="base"
                            fontFamily="medium"
                            variant="paragraph"
                        >
                            Acesse a
                        </Typography>
                        <Typography size="base" fontFamily="medium">
                            Central de Atendimento
                        </Typography>
                    </Stack>
                </Styled.CallCenterStack>
            </CustomLink>

            <SocialMedia />
            <Styled.Divider />
            <Institutional />
        </Styled.ContainerStack>
    )
}
