import { Stack } from '@components/Stack'
import { Button } from '@components/ui/Button'
import { HeadsetMic } from '@material-ui/icons'
import styled from 'styled-components'

export const ContainerStack = styled(Stack)`
    background: ${({ theme }) =>
        process.env.NEXT_PUBLIC_IS_BLACK_FRIDAY === 'true'
            ? theme.colors.dark[1000]
            : theme.colors.gray[800]};
    color: ${({ theme }) => theme.colors.gray[50]};
    min-height: 600px;
    padding: 24px 16px 32px;
    width: 100%;
`

export const SacButton = styled(Button)`
    margin: 24px 0;

    .MuiButton-label {
        color: ${({ theme }) => theme.colors.gray[800]};
    }
`

export const CallCenterStack = styled(Stack)`
    margin: 24px 0;
`

export const HeadsetMicIcon = styled(HeadsetMic)`
    width: 40px;
    height: 40px;
`
export const Divider = styled.span`
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
    opacity: 20%;
    margin: 24px 0;
`
