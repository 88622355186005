import styled from 'styled-components'
import { Stack } from '@components/Stack'
import { Typography } from '@components/ui/Typography'

export const ContainerStack = styled(Stack).attrs({
    spacing: 16
})`
    color: ${({ theme }) => theme.colors.white};
`

export const SubContainerStack = styled(Stack).attrs(({ theme }) => ({
    spacing: 8,
    direction: theme.isDesktop ? 'column' : 'row'
}))``

export const Text = styled(Typography).attrs(({ theme }) => ({
    fontFamily: 'regular',
    size: theme.isDesktop ? 'base' : 'sm'
}))`
    white-space: nowrap;
`

export const ItemContainer = styled(Stack).attrs(({ theme }) => ({
    fullWidth: true,
    spacing: theme.isDesktop ? 12 : 8
}))``

export const ItemContainerBottom = styled(Stack).attrs({
    fullWidth: true
})`
    margin-top: 8px;
`
