import { CategoryList } from '@components/Layout/components/CategoryList'
import { ComponentBaseProps } from '@static/types'
import * as Styled from './styles'

export const FooterCategoriesMobileView = ({
    categoriesData
}: ComponentBaseProps): JSX.Element => {
    return (
        <Styled.Container>
            <Styled.CategoryTitle>Categorias</Styled.CategoryTitle>
            <CategoryList
                categories={categoriesData?.start}
                menuTheme="dark"
                initialOpen={true}
            />
        </Styled.Container>
    )
}
